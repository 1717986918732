import * as React from "react";
import Layout from "../../components/layout";
import Image from "../../components/image";
import PodcastPlayer from "../../components/podcastplayer";

import lyder from "./img/lyder.jpg";
import just from "./img/just.jpg";
import tur from "./img/aretsforstetur.jpg";
import band from "./img/nyttband.jpg";
import jonasoglinn from "./img/jonasoglinn.jpg";


export default () => (
    <Layout title={"Julekort 2022"}>
        <div id="content" class="content-region">
            <h1>🎄 God Jul 🎅</h1>
            <h4>De beste ønsker til alle venner og kjente, tusen takk for at dere finnes!</h4>
            <h4>Hilsen Just, Lyder, Linn, Jonas ❤️</h4>
        </div>
        <div id="content" class="content-region">
            <h3>Årets julepodcast:</h3>
            <PodcastPlayer
                podcastUrl="https://drive.google.com/uc?export=download&id=1913otX8OCRQVfEaYJbXmo6XITa3dryve"
                caption=""/>
        </div>
        <div id="content" class="content-region">
            <div>
                <h3>Bilder fra året som har gått</h3>
                <Image image={lyder} caption="Lyder på Cruise, Italia Juni 2022"/>
                <Image image={just} caption="Just klar for Cruise, Italia Juni 2022"/>
                <Image image={tur} caption="Årets første tur i bakken, Oppdal Desember 2022"/>
                <Image image={jonasoglinn} caption="Björk, Trondheim Juli 2022"/>
                <Image image={band} caption="Jonas live med nytt band, Oslo November 2022"/>
            </div>
        </div>
    </Layout>
)
